import React from 'react';
import ReactDOM from 'react-dom';
import {Loader} from './components/atoms';
import SnackbarProvider from 'react-simple-snackbar';

import './assets/scss/index.scss';
import 'rc-slider/assets/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

// DayJS
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const App = React.lazy(() => import('./App'));

ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </React.Suspense>,
  document.getElementById('root'),
);
